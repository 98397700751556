<template>
    <div :class="['modal',showModal?'modal-open':'']" style="display: block" @keyup.esc="onClose">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-header">

                    <h3 class="modal-content__title">MEHR FILTER</h3>

                    <button tabindex="0" type="button" class="close" ref="closeBtn" data-dismiss="modal" aria-label="Close" @click="onClose">
                        <span class="material-icons" aria-hidden="true">close</span>
                    </button>
                </div>


                <div class="filters text-left scrollbar">
                    <div v-for="(item, index) in filtersToShow" :key="item.field_type_id">
                        <div v-if="item.type=='depender'" >
                            <ul class="filter-choose mt20">
                                <StructuredMoreFiltersDependers :showCheckBoxAndLabel="index == 0? false: true" :level="parseInt(0)" :filter="item" :selectedFilters="tempFilters" 
                                :parentFilterId="item.field_type_id" @parentSelectionChanged="selectionChanged" @selectionChanged="selectionChanged"/>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-around mt40">
                    <button class="btn btn-link" @click="onClose">Abbrechen</button>
                    <button class="btn btn-secondary" @click="filteringFinished">{{contentCount}} Ergebnisse anzeigen</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StructuredMoreFilterModal",
        components: {
            StructuredMoreFiltersDependers: () => import('./StructuredMoreFiltersDependers'),
        },
        props: {
            showModal: {
                type: Boolean,
                default: false,
            },
            structuredFilters: {
                type: Array,
                required: true,
            },
            selectedFilters: {
                type: Map,
                default() {
                  return new Map();
              }
          },
          contentCount: {
            tyle: Number,
            default: 0
        }
    },
    data() {
        return {
            tempFilters: new Map,
            awaitingFilterSelectionFinished: false,
        }
    },
    created() {
        /*fill the tempFilters with the values of the "real" Filter */
        this.tempFilters = new Map([...this.tempFilters,...this.selectedFilters]);
        this.$nextTick(() => {
            this.$refs.closeBtn.focus();
        });
    },
    computed: {
        filtersToShow() {
            var filters = [];
            /*we're just looking for the upper level because the category-filter is set elsewhere but influences the types of filters to be shown here*/
            if(this.selectedFilters!==null && this.structuredFilters!==null && this.structuredFilters.length>0){
                for(var i = 0; i < this.structuredFilters.length; i++){
                    /*we are only interessted in fields that are dependend on other fields*/
                    if(this.structuredFilters[i].type=="depender"){
                        var vals = this.getSelectedValuesForFieldType(this.structuredFilters[i].field_type_id);
                        /*iterate through the options of this depender and add only those, that have a match to the value already set*/
                        if(vals!==null){
                            for(var j = 0; j < this.structuredFilters[i].options.length; j++){
                                if(vals.includes(this.structuredFilters[i].options[j].label)){
                                    filters.push(this.structuredFilters[i].options[j]);
                                }
                            }
                        }
                        else{
                         filters.push(this.structuredFilters[i]);                             
                     }
                 }
                 else{
                        //filters.push(this.structuredFilters[i]);
                    }
                }
            }
            return filters;
        },
    },
    methods: {
        onClose() {
            this.$emit('on-close');
        },
        filteringFinished(){
            var filterForMap = new Map([...this.tempFilters,...this.selectedFilters]);
            this.$emit('filteringFinished',filterForMap);
        },
        selectionChanged(fieldTypeId,values){
            if(values!==null && values.length>0){
                this.tempFilters.set(fieldTypeId,values);
            }
            else{
                this.tempFilters.delete(fieldTypeId);
            }

            /*let's delay this so we don't get swamped by requests*/
            if (!this.awaitingFilterSelectionFinished) {
              setTimeout(() => {
                  this.$emit('getNumberOfResults',this.tempFilters);
                  this.awaitingFilterSelectionFinished = false;
          }, 2000); // 2 sec delay
          }
          this.awaitingFilterSelectionFinished = true;
      },
      getSelectedValuesForFieldType(fieldTypeId){
        if(this.selectedFilters!==null){
            var res = this.selectedFilters.get(fieldTypeId);
            if(res!==null && res !== undefined){
                if(Array.isArray(res) && res.length>0){
                    return res.length>1?res:res[0];
                }
                else{
                    return res;
                }
            }
        }
        return null;
    },
}
};
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .scrollbar{
    overflow-x: auto;
    padding-right: 30px;
}

</style>